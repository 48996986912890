@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind typography;

@import url("https://fonts.cdnfonts.com/css/space-grotesk");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~tippy.js/themes/light-border.css";
@import "~tippy.js/themes/material.css";

@import "~tippy.js/dist/tippy.css";
@import "ngx-toastr/toastr";

@import url("./assets/fonts/fontello/css/invest.css");

@font-face {
  font-family: "invest";
  src: url("./assets/fonts/fontello/font/invest.eot?19967734");
  src: url("./assets/fonts/fontello/font/invest.eot?19967734#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/fontello/font/invest.woff?19967734") format("woff"),
    url("./assets/fonts/fontello/font/invest.ttf?19967734") format("truetype"),
    url("./assets/fonts/fontello/font/invest.svg?19967734#invest") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Base */
html {
  font-size: 16px;
}
html,
body,
html:focus-within,
* * {
  scroll-behavior: smooth !important;
}

/* Para telas menores, como smartphones */
@media (max-width: 767px) {
  html {
    font-size: 11px;
  }
  .container{
    padding-inline: 0!important;
  }
}

/* Para tablets e telas de tamanho médio */
@media (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: 12px;
  }
}

/* Para telas de tamanho médio a grande */
@media (min-width: 1024px) and (max-width: 1399px) {
  html {
    font-size: 13px;
  }
}

/* Para telas grandes */
@media (min-width: 1400px) {
  html {
    font-size: 14px;
  }
}

/* Para telas grandes */
@media (min-width: 1600px) {
  html {
    font-size: 15px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
}

.git {
  background: #000;
}

** {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

a {
  &:hover {
    text-decoration: underline;
  }
}


/* Para o Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Para o Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ads-container{
  padding: .25em;
}

.ads-item{
  background-color: #c5c5c5;
}

.primary {
  background-color: #2563eb;
  outline: 2px solid transparent;
  color: #ffffff;
  font-weight: 800;
  border:none;
  &:hover {
    background-color: #3b82f6;
  }
  &:focus {
    outline: 2px solid #93c5fd;
    outline-offset: 2px;
  }
}


.tertiary {
  background-color: #111827;
  outline: 2px solid transparent;
  color: #ffffff;
  font-weight: 800;
  border:none;
  &:hover {
    background-color: #1f2937;
    color: #ffffff;
  }
  &:focus {
    outline: 2px solid #d1d5db;
    outline-offset: 2px;
  }
}

.quaternary {
  background-color: #7c3aed;
  outline: 2px solid transparent;
  color: #ffffff;
  font-weight: 800;
  border:none;
  &:hover {
    background-color: #8b5cf6;
    color: #ffffff;
  }
  &:focus {
    outline: 2px solid #c4b5fd;
    outline-offset: 2px;
  }
}

.danger {
  background-color: #dc2626;
  outline: 2px solid transparent;
  color: #ffffff;
  font-weight: 800;
  border:none;
  &:hover {
    background-color: #ef4444;
    color: #ffffff;
  }
  &:focus {
    outline: 2px solid #fca5a5;
    outline-offset: 2px;
  }
}